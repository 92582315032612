import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_navigation_drawer, {
      modelValue: _ctx.drawer,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.drawer) = $event)),
      app: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_list, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, index) => {
              return (_openBlock(), _createBlock(_component_v_list_item, {
                key: item.title,
                to: item.submenu ? '' : item.route,
                router: "",
                onClick: ($event: any) => (_ctx.toggleSubMenu(index))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.icon), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_v_list_item_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(this.$t(item.title)), 1)
                    ]),
                    _: 2
                  }, 1024),
                  (_ctx.isSubMenuActive(index))
                    ? (_openBlock(), _createBlock(_component_v_list, { key: 0 }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.submenu, (subitem) => {
                            return (_openBlock(), _createBlock(_component_v_list_item, {
                              key: subitem.title,
                              to: subitem.route,
                              router: "",
                              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(subitem.icon), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_list_item_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(this.$t(subitem.title)), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["to"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["to", "onClick"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_app_bar, { app: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_app_bar_nav_icon, {
          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.drawer = !_ctx.drawer), ["stop"]))
        }),
        _createVNode(_component_v_toolbar_title, null, {
          default: _withCtx(() => [
            _createTextVNode("SHMU")
          ]),
          _: 1
        }),
        _createVNode(_component_v_menu, null, {
          activator: _withCtx(({ props: menu }) => [
            _createVNode(_component_v_btn, _mergeProps({ color: "primary" }, _ctx.mergeProps(menu)), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.selectedLanguage), 1)
              ]),
              _: 2
            }, 1040)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (item, index) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    key: index,
                    onClick: ($event: any) => (_ctx.changeLanguage(item))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_img, {
                        src: item.flag,
                        alt: item.label
                      }, null, 8, ["src", "alt"]),
                      _createVNode(_component_v_list_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.label), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}