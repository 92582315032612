import api from "@/api/api";

export interface AppConfig {
  maxFileSize: string;
  maxRequestSize: number;
  allowedFileTypes: string;
}

export async function loadAppConfig(): Promise<AppConfig> {
  try {
    const response = await api.endpoints.loadAppConfig();
    return response.data as AppConfig;
  } catch (error) {
    console.error("Failed to load app config:", error);
    throw error;
  }
}
