<template>
  <v-navigation-drawer v-model="drawer" app>
    <v-list>
      <v-list-item
        v-for="(item, index) in menuItems"
        :key="item.title"
        :to="item.submenu ? '' : item.route"
        router
        @click="toggleSubMenu(index)"
      >
        <v-icon>{{ item.icon }}</v-icon
        ><v-list-item-title>{{ this.$t(item.title) }}</v-list-item-title>

        <v-list v-if="isSubMenuActive(index)">
          <v-list-item
            v-for="subitem in item.submenu"
            :key="subitem.title"
            :to="subitem.route"
            router
            @click.stop
          >
            <v-icon>{{ subitem.icon }}</v-icon>
            <v-list-item-title>{{ this.$t(subitem.title) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

  <v-app-bar app>
    <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    <v-toolbar-title>SHMU</v-toolbar-title>
    <v-menu>
      <template v-slot:activator="{ props: menu }">
        <v-btn color="primary" v-bind="mergeProps(menu)">
          {{ selectedLanguage }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in languages"
          :key="index"
          @click="changeLanguage(item)"
        >
          <v-img :src="item.flag" :alt="item.label"></v-img>
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script lang="ts">
import { defineComponent, mergeProps } from "vue";

interface Language {
  label: string;
  value: string;
  flag: string;
}

interface MenuItem {
  title: string;
  route: string;
  icon: string;
  submenu?: SubMenuItem[];
}

interface SubMenuItem {
  title: string;
  route: string;
  icon: string;
}

export default defineComponent({
  name: "MainMenu",

  data: () => ({
    drawer: false,
    activeItem: null as number | null,
    menuItems: [
      { title: "Home", icon: "mdi-zip-disk", route: "/" },
      { title: "About", icon: "mdi-playlist-check", route: "/submissions" },
      {
        title: "CodeLists",
        icon: "mdi-database-edit-outline",
        route: "/code-lists",
      },
      {
        title: "ErrorLogs",
        icon: "mdi-alert-circle",
        route: "/error-logs",
      },
      /*{
        title: "SubmissionViewer",
        icon: "mdi-eye",
        route: "/submission/2",
      },*/
      {
        title: "CataloguesList",
        icon: "mdi-database-cog-outline",
        route: "",
        submenu: [
          {
            title: "MonitoringPoint",
            route: "/monpoint",
            icon: "mdi-submenu1",
          },
          {
            title: "Environment",
            route: "/environment",
            icon: "mdi-submenu2",
          },
          {
            title: "WaterShape",
            route: "/water-shape",
            icon: "mdi-submenu3",
          },
          {
            title: "Operator",
            route: "/operator",
            icon: "mdi-submenu4",
          },
        ],
      },
      {
        title: "WaterEvidence",
        icon: "mdi-alert-circle",
        route: "/water-evidence",
      },
    ] as MenuItem[],
    languages: [
      {
        label: "English",
        value: "en",
        flag: require("@/assets/flags/4x3/gb.svg"),
      },
      {
        label: "Slovak",
        value: "sk",
        flag: require("@/assets/flags/4x3/sk.svg"),
      },
    ] as Language[],
    selectedLanguage: "sk",
  }),

  methods: {
    mergeProps,
    changeLanguage(item: Language) {
      this.$i18n.locale = item.value;
      this.selectedLanguage = item.value;
    },
    toggleSubMenu(itemIndex: number): void {
      if (this.activeItem === itemIndex) {
        this.activeItem = null;
      } else {
        this.activeItem = itemIndex;
      }
    },
    isSubMenuActive(itemIndex: number): boolean {
      return this.activeItem === itemIndex;
    },
  },
});
</script>

<style lang="scss" scoped>
.menu-list {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.menu-list li {
  margin: 0 10px;
}
</style>
