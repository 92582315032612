import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { createI18n } from "vue-i18n";
import PrimeVue from "primevue/config";

import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import AdminSubmissionViewer from "@/components/AdminSubmissionViewer.vue";

loadFonts();

const i18n = createI18n({
  locale: "sk",
  messages: {
    en: require("./locales/en.json"),
    sk: require("./locales/sk.json"),
  },
});

const app = createApp(App);

app.use(router);
app.use(vuetify);
app.use(i18n);
app.use(PrimeVue);

app.component("submission-viewer-web-component", AdminSubmissionViewer);

app.mount("#app");
