import axios from "axios";

const BASE_URL = window.location.origin + "/api";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-type": "application/json",
  },
});

const endpoints = {
  getTemplates: () => axiosInstance.get(`/templates`),
  uploadTemplate: (data: FormData) =>
    axiosInstance.post(`/templates/upload-template`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  deleteTemplate: (id: number) => axiosInstance.delete(`/templates/${id}`),
  search: (page: number, perPage: number, search: unknown, sortBy: unknown) =>
    axiosInstance.post(`/submissions/search`, {
      pageNumber: page,
      pageSize: perPage,
      filter: search,
      sorting: sortBy,
    }),
  deleteSubmission: (id: number) => axiosInstance.delete(`/submissions/${id}`),
  refreshCodeList: (code: string | null) =>
    axiosInstance.get(`/code-list/update/${code}`),
  searchCodeList: (page: number, perPage: number, search: any, sortBy: any) =>
    axiosInstance.post(`/code-list/search`, {
      pageNumber: page,
      pageSize: perPage,
      filter: search,
      sorting: sortBy,
    }),
  searchCatalog: (
    page: number,
    perPage: number,
    search: any,
    sortBy: any,
    catalog: string
  ) =>
    axiosInstance.post(`/catalog/${catalog}/search`, {
      pageNumber: page,
      pageSize: perPage,
      filter: search,
      sorting: sortBy,
    }),
  searchErrors: (page: number, perPage: number, search: any, sortBy: any) =>
    axiosInstance.post(`/error-logs/search`, {
      pageNumber: page,
      pageSize: perPage,
      filter: search,
      sorting: sortBy,
    }),
  getNewSubmission: (templateId: number) =>
    axiosInstance.post(`/submissions/create-submission-from/${templateId}`),
  getSubmission: (id: number) => axiosInstance.get(`/submissions/${id}`),
  getSubmissionState: (id: any) =>
    axiosInstance.get(`/submissions/${id}/state`),
  saveSubmission: (id: number, submission: FormData) =>
    axiosInstance.post(`/submissions/${id}`, submission, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  getSubmissionAttachments: (id: number) =>
    axiosInstance.get(`/submissions/${id}/attachments`),
  loadAppConfig: () => axiosInstance.get(`/config`),
  submit: (id: number, submission: FormData) =>
    axiosInstance.post(`/submissions/submit/${id}`, submission, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  uploadAttachment: (id: any, formData: FormData) =>
    axiosInstance.post(`/submissions/${id}/upload-attachment`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  deleteAttachment: (id: number, submissionId: any) =>
    axiosInstance.delete(
      `/submissions/${submissionId}/delete-attachment/${id}`
    ),
};

export default {
  axiosInstance,
  endpoints,
  BASE_URL,
};
