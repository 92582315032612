import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import EformsView from "../views/EformsView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: EformsView,
  },
  {
    path: "/submissions",
    name: "submissions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/EformsInstancesView.vue"
      ),
  },
  {
    path: "/code-lists",
    name: "code-lists",
    component: () =>
      import(/* webpackChunkName: "code-lists" */ "../views/CodeListView.vue"),
  },
  {
    path: "/error-logs",
    name: "error-logs",
    component: () =>
      import(/* webpackChunkName: "error-logs" */ "../views/ErrorLogsView.vue"),
  },
  {
    path: "/monpoint",
    name: "monpoint",
    component: () =>
      import(/* webpackChunkName: "monpoint" */ "../views/MonPointView.vue"),
  },
  {
    path: "/environment",
    name: "environment",
    component: () =>
      import(
        /* webpackChunkName: "environment" */ "../views/EnvironmentView.vue"
      ),
  },
  {
    path: "/water-shape",
    name: "water-shape",
    component: () =>
      import(
        /* webpackChunkName: "water-shape" */ "../views/WaterShapeView.vue"
      ),
  },
  {
    path: "/water-evidence",
    name: "water-evidence",
    component: () =>
      import(
        /* webpackChunkName: "water-evidence" */ "../views/WaterEvidenceView.vue"
      ),
  },
  {
    path: "/operator",
    name: "operator",
    component: () =>
      import(/* webpackChunkName: "operator" */ "../views/OperatorView.vue"),
  },
  {
    path: "/submission/:id",
    name: "submission-view",
    component: () =>
      import(
        /* webpackChunkName: "submission-viewer" */ "../views/SubmissionViewerView.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
