<template>
  <Card class="mt-10 mr-5 ml-5 mb-10">
    <template #content>
      <div class="iframe-container" v-if="origin">
        <iframe
          name="my-frame"
          width="100%"
          height="auto"
          ref="myIframe"
          scrolling="no"
        />
      </div>
      <div class="margin-top-2-em" v-if="!showXmlDialog">
        <button
          @click="validateForm()"
          class="govuk-button govuk-margin-right-1"
        >
          Skontrolovať
        </button>
        <button
          @click="submitForm(this.$route.params.id)"
          class="govuk-button govuk-margin-right-1"
        >
          Odoslať
        </button>
        <button @click="showDialog()" class="govuk-button govuk-margin-right-1">
          Načítať z XML
        </button>
        <button
          @click="downloadXml()"
          class="govuk-button govuk-margin-right-1"
        >
          Uložiť XML
        </button>
      </div>
      <div
        id="loadXmlDialog"
        class="dialog showLoadXmlWithLocalStorage mt-5"
        v-if="showXmlDialog"
      >
        <button
          data-module="govuk-button"
          class="govuk-button"
          style="margin-bottom: 12px"
          type="button"
          id="hide-load-dialog"
          @click="hideXmlDialog()"
        >
          Skryť
        </button>
        <div class="container">
          <div class="govuk-form-group column-full">
            <label class="govuk-label" for="load-file">Načítať zo súboru</label
            ><br />
            <input
              type="file"
              name="load-file"
              id="load-file"
              class="govuk-file-upload"
              @change="handleLoadXml"
            />
          </div>
          <div class="govuk-form-group column-full mt-5">
            <button
              data-module="govuk-button"
              class="govuk-button"
              type="button"
              id="load-btn"
              @click="loadXml()"
            >
              Načítať
            </button>
          </div>
        </div>
      </div>
    </template>
  </Card>
  <v-card class="mt-10 mr-5 ml-5 mb-10">
    <h2 class="mt-5 ml-5">{{ this.$t("attachments") }}</h2>
    <v-file-input
      label="File input"
      class="custom-input-field ml-5"
      :accept="allowedFileTypes"
      @change="handleFileUpload"
    ></v-file-input>
    <div v-if="uploadedFiles.length > 0" class="ml-15">
      <h4>List of uploaded files:</h4>
      <ul class="mb-5">
        <li v-for="file in uploadedFiles" :key="file.name" class="no-marker">
          {{ file.name }}
          <v-icon @click="handleDeleteFile(file.id)" class="text-red"
            >mdi-close-circle
          </v-icon>
        </li>
      </ul>
    </div>
    <div v-else class="ml-15 mb-5">
      <p>{{ this.$t("noFilesUploaded") }}</p>
    </div>
  </v-card>
  <div class="text-center ma-2">
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:actions>
        <v-btn color="red" variant="text" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { defineComponent, defineCustomElement } from "vue";
import api from "@/api/api";
import router from "@/router";
import Card from "primevue/card";
import Button from "primevue/button";
import FileUpload from "primevue/fileupload";
import { loadAppConfig } from "@/config";
import axios from "axios";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const YmsMapPlugin = require("@yms/map-plugin-opensource/");

export default defineComponent({
  name: "SubmissionViewerWebComponent",
  components: { Card },
  data() {
    return {
      htmlCode: "",
      uploadedFiles: [] as File[],
      maxFileSize: "10MB",
      snackbar: false,
      text: ``,
      timeout: 5000,
      allowedFileTypes: "",
      origin: "",
      showXmlDialog: false,
      xmlSelectedFileData: "",
      isSubmitted: false,
      autoSaveInterval: 0,
    };
  },
  mounted() {
    (window as any).transformCoordinateData = (data: CoordinateData) => {
      console.log("Custom function called!");
      console.log(data);
      try {
        new YmsMapPlugin.MapPluginSHMU();
      } finally {
        return new YmsMapPlugin.MapUtils().transformCoordinate(data);
      }
    };

    this.origin = window.location.origin;
    this.getSubmissionHtml();
    this.loadConfig();
    this.fetchAttachmentsFromServer(this.$route.params.id);
    window.addEventListener("message", this.handleMessage);
    api.endpoints.getSubmissionState(this.$route.params.id).then((response) => {
      const submissionState = response as unknown as string;
      if (submissionState != "DRAFT") {
        this.isSubmitted = true;
        if (this.autoSaveInterval) {
          clearInterval(this.autoSaveInterval);
        }
      } else {
        this.autoSaveInterval = setInterval(() => {
          if (!this.isSubmitted) {
            this.saveForm(this.$route.params.id);
          }
        }, 30000);
      }
    });
  },
  unmounted() {
    window.removeEventListener("message", this.handleMessage);
  },
  methods: {
    loadConfig() {
      loadAppConfig()
        .then((config) => {
          this.maxFileSize = config.maxFileSize;
          this.allowedFileTypes = config.allowedFileTypes;
        })
        .catch((error) => {
          console.error("Failed to load app config:", error);
        });
    },
    parseFileSize(fileSize: string): number {
      const fileSizeRegex = /^(\d+(?:\.\d+)?)\s*(\w+)$/;
      const matches = fileSize.match(fileSizeRegex);

      if (!matches || matches.length !== 3) {
        throw new Error("Invalid file size format from the server.");
      }

      const size = parseFloat(matches[1]);
      const unit = matches[2].toLowerCase();

      switch (unit) {
        case "kb":
          return size * 1024;
        case "mb":
          return size * 1024 * 1024;
        case "gb":
          return size * 1024 * 1024 * 1024;
        default:
          throw new Error("Invalid file size unit from the server.");
      }
    },
    async fetchAttachmentsFromServer(id: any) {
      try {
        const response = await api.endpoints.getSubmissionAttachments(id);
        this.uploadedFiles = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async handleFileUpload(event: Event) {
      const maxFileSizeInBytes = this.parseFileSize(this.maxFileSize);
      const currentFile = (event.target as HTMLInputElement).files?.[0];
      const id = this.$route.params.id;
      if (currentFile && currentFile.size <= maxFileSizeInBytes) {
        const formData = new FormData();
        const blob = new Blob([currentFile], { type: currentFile.type });
        formData.append("file", blob, currentFile.name);
        try {
          await api.endpoints.uploadAttachment(id, formData);
          this.snackbar = true;
          this.text = "File uploaded successfully";
        } catch (error: any) {
          this.snackbar = true;
          this.text = error.response.data;
        }
        this.fetchAttachmentsFromServer(id);
      } else if (currentFile && currentFile.size > maxFileSizeInBytes) {
        this.snackbar = true;
        this.text = this.$t("maxFileSizeError") + this.maxFileSize;
        (event.target as HTMLInputElement).value = "";
      }
    },
    async handleDeleteFile(fileId: number) {
      const id = this.$route.params.id;
      await api.endpoints.deleteAttachment(fileId, id);
      this.fetchAttachmentsFromServer(id);
    },
    handleMessage(event: MessageEvent) {
      // TODO: docasny check, aby sme nezobrazovali iframe z ineho zdroja
      /* if (event.origin !== this.origin) {
        console.error("Invalid origin.");
        return;
      }*/

      if (event.data === "CONTENT_CHANGED") {
        this.resizeIframe();
      }
    },

    resizeIframe() {
      const iframe = this.$refs.myIframe as HTMLIFrameElement;
      const contentHeight = iframe.contentWindow?.document?.body?.scrollHeight;
      iframe.style.height = contentHeight + "px";
    },
    router() {
      return router;
    },

    handleLoadXml(event: Event) {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        const selectedFile = input.files[0];
        const reader = new FileReader();

        reader.onload = (fileEvent) => {
          if (fileEvent.target) {
            this.xmlSelectedFileData = fileEvent.target.result as string;
          }
        };

        reader.readAsText(selectedFile);
      }
    },

    loadXml() {
      let myIframe = this.$refs.myIframe as HTMLIFrameElement;
      let contentWindow = myIframe.contentWindow as Window & Eform;
      contentWindow.LoadXml(this.xmlSelectedFileData);
      this.xmlSelectedFileData = "";
      this.showXmlDialog = false;
    },

    showDialog() {
      this.showXmlDialog = true;
    },

    hideXmlDialog() {
      this.showXmlDialog = false;
    },

    downloadXml() {
      let myIframe = this.$refs.myIframe as HTMLIFrameElement;
      let contentWindow = myIframe.contentWindow as Window & Eform;
      let xmlData = contentWindow.GetData().replace(/\r?\n|\t/g, "");
      xmlData = xmlData.replace(/\"/g, '"');
      const blob = new Blob([xmlData], { type: "text/xml" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "data.xml";
      link.click();
      window.URL.revokeObjectURL(link.href);
    },

    validateForm() {
      let myIframe = this.$refs.myIframe as HTMLIFrameElement;
      let contentWindow = myIframe.contentWindow as Window & Eform;
      contentWindow.ValidateForm();
    },

    async submitForm(id: any) {
      let myIframe = this.$refs.myIframe as HTMLIFrameElement;
      let contentWindow = myIframe.contentWindow as Window & Eform;
      const isValid = contentWindow.ValidateForm();
      if (isValid) {
        let xmlData = contentWindow.GetData().replace(/\r?\n|\t/g, "");
        xmlData = xmlData.replace(/\"/g, '"');
        const formData = new FormData();
        formData.append("xmlData", xmlData);
        try {
          await api.endpoints.submit(id, formData).then((response) => {
            if (response.data) {
              response.data === "WAITING_FOR_APPROVAL"
                ? (this.isSubmitted = true)
                : (this.isSubmitted = false);
            }
            if (this.isSubmitted) {
              const event = new CustomEvent("submitEvent", {
                detail: {
                  message: "Hello from Vue component!",
                  state: response.data,
                },
                bubbles: true, // Ensure event bubbles up through the DOM
              });
              this.$el.dispatchEvent(event);
            }
            if (this.isSubmitted && this.autoSaveInterval) {
              clearInterval(this.autoSaveInterval);
            }
            this.snackbar = true;
            this.text = this.$t("submissionSavedSuccessfully");
          });
        } catch (error: any) {
          this.snackbar = true;
          this.text = error.response.data;
        }
      } else {
        this.snackbar = true;
        this.text = "form is not valid";
      }
    },

    async getSubmissionHtml() {
      try {
        const url =
          this.origin +
          "/api/submissions/" +
          this.$route.params.id +
          "?url=" +
          this.origin;

        const res = await fetch(url, {
          method: "GET",
        });
        const blob = await res.blob();

        const urlObject = URL.createObjectURL(blob);
        const myIframe = this.$refs.myIframe as HTMLIFrameElement;
        myIframe.src = urlObject;
      } catch (error) {
        console.error(error);
      }
    },

    async saveForm(id: any) {
      let myIframe = this.$refs.myIframe as HTMLIFrameElement;
      let contentWindow = myIframe.contentWindow as Window & Eform;

      let xmlData = contentWindow.GetData().replace(/\r?\n|\t/g, "");
      xmlData = xmlData.replace(/\"/g, '"');
      const formData = new FormData();
      /*this.uploadedFiles.forEach((file) => {
        const blob = new Blob([file], { type: file.type });
        formData.append("files", blob, file.name);
      });*/
      formData.append("xmlData", xmlData);

      try {
        await api.endpoints.saveSubmission(id, formData).then((response) => {
          if (response.data) {
            response.data === "WAITING_FOR_APPROVAL"
              ? (this.isSubmitted = true)
              : (this.isSubmitted = false);
          }
          if (this.isSubmitted && this.autoSaveInterval) {
            clearInterval(this.autoSaveInterval);
          }
          this.snackbar = true;
          this.text = this.$t("submissionSavedSuccessfully");
        });
      } catch (error: any) {
        this.snackbar = true;
        this.text = error.response.data;
      }
    },
  },
});

/*const SubmissionViewerWebComponent = defineComponent({
  name: "submission-viewer-web-component",
  components: {},
  data() {
    return {
      htmlCode: "",
    };
  },
  methods: {
    router() {
      return router;
    },
    saveForm(id: number) {
      let myIframe = this.$refs.myIframe as HTMLIFrameElement;
      let contentWindow = myIframe.contentWindow as Window & Eform;

      let xmlData = contentWindow.GetData().replace(/\r?\n|\t/g, "");
      xmlData = xmlData.replace(/\"/g, '"');

      api.endpoints.saveSubmission(id, xmlData).then((response) => {
        console.log(response);
      });
    },
  },
});*/

/*export const AdminSubmissionViewerElement = defineCustomElement(
  SubmissionViewerWebComponent
);

customElements.define(
  "submission-viewer-web-component",
  AdminSubmissionViewerElement
);*/
</script>

<style scoped>
.custom-input-field >>> .v-field__overlay {
  opacity: 0 !important; /* Custom opacity value */
  /* Add any other custom styles here */
}

.no-marker {
  list-style-type: none;
}

.govuk-button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.1875;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 0;
  margin-bottom: 22px;
  padding: 8px 10px 7px;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 0;
  color: #fff;
  background-color: #00703c;
  box-shadow: 0 2px 0 #002d18;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
  -webkit-appearance: none;
}

@media print {
  .govuk-button {
  }
}

@media (min-width: 40.0625em) {
  .govuk-button {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1;
  }
}

@media print {
  .govuk-button {
    font-size: 14pt;
    line-height: 19px;
  }
}

@media (min-width: 40.0625em) {
  .govuk-button {
    margin-bottom: 32px;
    width: auto;
  }
}

.govuk-button:active,
.govuk-button:hover,
.govuk-button:link,
.govuk-button:visited {
  color: #fff;
  text-decoration: none;
}

.govuk-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.govuk-button:hover {
  background-color: #005a30;
}

.govuk-button:active {
  top: 2px;
}

/*
 .govuk-button:focus {
  border-color: #ffdf0f;
  outline: 3px solid rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 0 1px #ffdf0f
}

.govuk-button:focus:not(:active):not(:hover) {
  border-color: #ffdf0f;
  color: #0b0c0c;
  background-color: #ffdf0f;
  box-shadow: 0 2px 0 #0b0c0c
}

 */

.govuk-button:before {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -4px;
  left: -2px;
  background: rgba(0, 0, 0, 0);
}

.govuk-button:active:before {
  top: -4px;
}

.govuk-button--disabled,
.govuk-button[disabled="disabled"],
.govuk-button[disabled] {
  opacity: 0.5;
}

.govuk-button--disabled:hover,
.govuk-button[disabled="disabled"]:hover,
.govuk-button[disabled]:hover {
  background-color: #00703c;
  cursor: default;
}

.govuk-button--disabled:focus,
.govuk-button[disabled="disabled"]:focus,
.govuk-button[disabled]:focus {
  outline: none;
}

.govuk-button--disabled:active,
.govuk-button[disabled="disabled"]:active,
.govuk-button[disabled]:active {
  top: 0;
  box-shadow: 0 2px 0 #002d18;
}

.govuk-button--secondary {
  background-color: #dee0e2;
  box-shadow: 0 2px 0 #858688;
}

.govuk-button--secondary,
.govuk-button--secondary:active,
.govuk-button--secondary:hover,
.govuk-button--secondary:link,
.govuk-button--secondary:visited {
  color: #0b0c0c;
}

.govuk-button--secondary:hover {
  background-color: #c8cacb;
}

.govuk-button--secondary:hover[disabled] {
  background-color: #dee0e2;
}

.govuk-button--warning {
  background-color: #d0190f;
  box-shadow: 0 2px 0 #530a06;
}

.govuk-button--warning,
.govuk-button--warning:active,
.govuk-button--warning:hover,
.govuk-button--warning:link,
.govuk-button--warning:visited {
  color: #fff;
}

.govuk-button--warning:hover {
  background-color: #a6140c;
}

.govuk-button--warning:hover[disabled] {
  background-color: #d0190f;
}

.govuk-button--start {
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: auto;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (min-width: 40.0625em) {
  .govuk-button--start {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1;
  }
}

@media print {
  .govuk-button--start {
    font-size: 18pt;
    line-height: 1;
  }
}

.govuk-button__start-icon {
  margin-left: 5px;
  vertical-align: middle;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-item-align: center;
  align-self: center;
}

@media (min-width: 48.0625em) {
  .govuk-button__start-icon {
    margin-left: 10px;
  }
}

.govuk-margin-right-1 {
  margin-right: 5px !important;
}

.margin-top-2-em {
  margin-top: 2em !important;
}
</style>
