<template>
  <eforms-list />
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Components
import EformsList from "../components/Eforms.vue";

export default defineComponent({
  name: "EformsView",

  components: {
    EformsList,
  },
});
</script>
