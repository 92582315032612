<template>
  <v-app>
    <v-main>
      <MainMenu />
      <router-view />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainMenu from "@/components/MainMenu.vue";

export default defineComponent({
  name: "App",
  components: {
    MainMenu,
  },
  data() {
    return {
      //
    };
  },
});
</script>
